import "./privacy.css";

function Privacy() {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      {/* Table of Contents */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold">Table of Contents</h2>
        <ol className="list-decimal ml-6 mt-4 space-y-2">
          <li>
            <a className="hover:underline" href="#section1">
              1. Interpretation and Definitions
            </a>
          </li>
          <li>
            <a className="hover:underline" href="#section2">
              2. Information We Collect
            </a>
            <ol className="list-decimal ml-6 space-y-2">
              <li>
                <a className="hover:underline" href="#section2a">
                  a. Personal Information
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section2b">
                  b. Usage Data
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section2c">
                  c. Location Information
                </a>
              </li>
            </ol>
          </li>
          <li>
            <a className="hover:underline" href="#section3">
              3. How We Use Your Personal Information
            </a>
          </li>
          <li>
            <a className="hover:underline" href="#section4">
              4. Disclosure of Your Personal Information
            </a>
            <ol className="list-decimal ml-6 space-y-2">
              <li>
                <a className="hover:underline" href="#section4a">
                  a. Service Providers
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section4b">
                  b. Business Transfers
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section4c">
                  c. Affiliates
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section4d">
                  d. Business Partners
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section4e">
                  e. Other Users
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section4f">
                  f. With Your Consent
                </a>
              </li>
            </ol>
          </li>
          <li>
            <a className="hover:underline" href="#section5">
              5. Retention of Your Personal Information
            </a>
          </li>
          <li>
            <a className="hover:underline" href="#section6">
              6. Transfer of Your Personal Information
            </a>
          </li>
          <li>
            <a className="hover:underline" href="#section7">
              7. Your Data Privacy Rights
            </a>
            <ol className="list-decimal ml-6 space-y-2">
              <li>
                <a className="hover:underline" href="#section7a">
                  a. Access, Correction, and Deletion
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section7b">
                  b. Object to Processing
                </a>
              </li>
            </ol>
          </li>
          <li>
            <a className="hover:underline" href="#section8">
              8. Disclosure of Your Personal Information
            </a>
            <ol className="list-decimal ml-6 space-y-2">
              <li>
                <a className="hover:underline" href="#section8a">
                  a. Business Transactions
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section8b">
                  b. Legal Requirements
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section8c">
                  c. Protecting Our Rights
                </a>
              </li>
            </ol>
          </li>
          <li>
            <a className="hover:underline" href="#section9">
              9. Security of Your Personal Information
            </a>
          </li>
          <li>
            <a className="hover:underline" href="#section10">
              10. Third-Party Service Providers
            </a>
            <ol className="list-decimal ml-6 space-y-2">
              <li>
                <a className="hover:underline" href="#section10a">
                  a. Google Places
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section11">
                  11. Children's Privacy
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section12">
                  12. Links to Other Websites
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section13">
                  13. Changes to This Privacy Policy
                </a>
              </li>
              <li>
                <a className="hover:underline" href="#section14">
                  14. Contact Us
                </a>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      {/* Section 1: Interpretation and Definitions */}
      <section id="section1" className="mb-8">
        <h2 className="text-2xl font-semibold">
          1. Interpretation and Definitions
        </h2>
        <p className="mb-4">
          The words with capitalized initials in this Privacy Policy have
          specific meanings, as described below:
        </p>
        <ul className="list-disc ml-6">
          <li className="mb-1">
            <strong>Service</strong> refers to Utepils, our
            application/software.
          </li>
          <li className="mb-1">
            <strong>We</strong>, <strong>Us</strong>, <strong>Our</strong>, or{" "}
            <strong>the Company</strong> refers to Utepils, the creator of the
            Service.
          </li>
          <li className="mb-1">
            <strong>You</strong> means the individual using the Service or the
            legal entity on whose behalf the individual is using the Service.
          </li>
          <li className="mb-1">
            <strong>Personal Information</strong> means data that can be used to
            identify an individual.
          </li>
          <li className="mb-1">
            <strong>Usage Data</strong> refers to data automatically collected
            by the Service during its use.
          </li>
          <li className="mb-1">
            <strong>Location Information</strong> refers to data related to your
            geographic location.
          </li>
        </ul>
      </section>

      {/* Section 2: Information We Collect */}
      <section id="section2" className="mb-8">
        <h2 className="text-2xl font-semibold">2. Information We Collect</h2>

        {/* Subsection 2a: Personal Information */}
        <section id="section2a" className="mb-4">
          <h3 className="text-xl font-semibold">a. Personal Information</h3>
          <p className="mb-2">
            While using our Service, we may ask you to provide certain
            personally identifiable information that may include:
          </p>
          <ul className="list-disc ml-6">
            <li className="mb-1">Email address</li>
            <li className="mb-1">First name and last name</li>
          </ul>
        </section>

        {/* Subsection 2b: Usage Data */}
        <section id="section2b" className="mb-4">
          <h3 className="text-xl font-semibold">b. Usage Data</h3>
          <p className="mb-2">
            Usage Data is automatically collected when you use the Service and
            may include:
          </p>
          <ul className="list-disc ml-6">
            <li className="mb-1">
              Your Device's Internet Protocol (IP) address
            </li>
            <li className="mb-1">Browser type and version</li>
            <li className="mb-1">Pages of the Service visited</li>
            <li className="mb-1">Date and time of visits</li>
            <li className="mb-1">Time spent on pages</li>
            <li className="mb-1">Unique device identifiers</li>
            <li className="mb-1">Other diagnostic data</li>
          </ul>
          {/* Additional content for mobile device information */}
          <p className="mb-2 mt-2">
            When you access the Service via a mobile device, we may also collect
            information such as your mobile device's type, unique ID, IP
            address, operating system, and mobile internet browser type.
          </p>
        </section>

        {/* Subsection 2c: Location Information */}
        <section id="section2c" className="mb-4">
          <h3 className="text-xl font-semibold">c. Location Information</h3>
          <p className="mb-2">
            With your consent, we may collect location information from your
            device. We use this information to provide certain features of the
            Service, such as [mention specific features]. You can enable or
            disable location access through your device settings.
          </p>
        </section>
      </section>

      <section id="section3" className="mb-8">
        <h2 className="text-2xl font-semibold">
          3. How We Use Your Personal Information
        </h2>
        <p className="mb-4">
          We use your Personal Information for various purposes, including:
        </p>
        <ul className="list-disc ml-6">
          <li className="mb-1">Providing and maintaining our Service</li>
          <li className="mb-1">Managing your account and registration</li>
          <li className="mb-1">Fulfilling contracts or purchases</li>
          <li className="mb-1">
            Contacting you for updates and informative communications
          </li>
          <li className="mb-1">
            Providing news, special offers, and information about our products
            and services
          </li>
          <li className="mb-1">Managing and responding to your requests</li>
          <li className="mb-1">Analyzing data and improving our Service</li>
          <li className="mb-1">
            Evaluating business transfers and similar transactions
          </li>
        </ul>
      </section>
      {/* Section 4: Disclosure of Your Personal Information */}
      <section id="section4" className="mb-8">
        <h2 className="text-2xl font-semibold">
          4. Disclosure of Your Personal Information
        </h2>
        <p className="mb-4">
          We may share your Personal Information in the following situations:
        </p>

        {/* Subsection 4a: Service Providers */}
        <section id="section4a" className="mb-4">
          <h3 className="text-xl font-semibold">a. Service Providers</h3>
          <p className="mb-2">
            We share information with Service Providers to analyze and monitor
            Service usage.
          </p>
        </section>

        {/* Subsection 4b: Business Transfers */}
        <section id="section4b" className="mb-4">
          <h3 className="text-xl font-semibold">b. Business Transfers</h3>
          <p className="mb-2">
            We may share or transfer your information during business
            transactions, mergers, acquisitions, or asset sales.
          </p>
        </section>

        {/* Subsection 4c: Affiliates */}
        <section id="section4c" className="mb-4">
          <h3 className="text-xl font-semibold">c. Affiliates</h3>
          <p className="mb-2">
            We share information with Our affiliates, in which case we will
            require those affiliates to honor this Privacy Policy. Affiliates
            include Our parent company and any other subsidiaries, joint venture
            partners, or other companies that We control or that are under
            common control with Us.
          </p>
        </section>

        {/* Subsection 4d: Business Partners */}
        <section id="section4d" className="mb-4">
          <h3 className="text-xl font-semibold">d. Business Partners</h3>
          <p className="mb-2">
            We share information with Our business partners to offer You certain
            products, services, or promotions.
          </p>
        </section>

        {/* Subsection 4e: Other Users */}
        <section id="section4e" className="mb-4">
          <h3 className="text-xl font-semibold">e. Other Users</h3>
          <p className="mb-2">
            Information shared publicly or with other users of our Service may
            be viewed by all users.
          </p>
        </section>

        {/* Subsection 4f: With Your Consent */}
        <section id="section4f" className="mb-4">
          <h3 className="text-xl font-semibold">f. With Your Consent</h3>
          <p className="mb-2">
            We may disclose your information for any other purpose with your
            consent.
          </p>
        </section>
      </section>
      {/* Section 5: Retention of Your Personal Information */}
      <section id="section5" className="mb-8">
        <h2 className="text-2xl font-semibold">
          5. Retention of Your Personal Information
        </h2>
        <p className="mb-4">
          We retain your Personal Information only for the duration necessary to
          fulfill the purposes outlined in this Privacy Policy or as required by
          applicable laws. Usage Data is retained for internal analysis
          purposes.
        </p>
      </section>

      {/* Section 6: Transfer of Your Personal Information */}
      <section id="section6" className="mb-8">
        <h2 className="text-2xl font-semibold">
          6. Transfer of Your Personal Information
        </h2>
        <p className="mb-4">
          Your information may be processed and transferred outside your
          jurisdiction. We take steps to ensure secure processing in compliance
          with this Privacy Policy and relevant data protection laws.
        </p>
      </section>

      {/* Section 7: Your Data Privacy Rights */}
      <section id="section7" className="mb-8">
        <h2 className="text-2xl font-semibold">7. Your Data Privacy Rights</h2>
        <p className="mb-4">You have the right to:</p>

        {/* Subsection 7a: Access, Correction, and Deletion */}
        <section id="section7a" className="mb-4">
          <h3 className="text-xl font-semibold">
            a. Access, Correction, and Deletion
          </h3>
          <p className="mb-2">
            Request access, correction, or deletion of your Personal
            Information.
          </p>
        </section>

        {/* Subsection 7b: Object to Processing */}
        <section id="section7b" className="mb-4">
          <h3 className="text-xl font-semibold">b. Object to Processing</h3>
          <p className="mb-2">
            Object to the processing of your Personal Information.
          </p>
        </section>
      </section>

      {/* Section 8: Disclosure of Your Personal Information */}
      <section id="section8" className="mb-8">
        <h2 className="text-2xl font-semibold">
          8. Disclosure of Your Personal Information
        </h2>

        {/* Subsection 8a: Business Transactions */}
        <section id="section8a" className="mb-4">
          <h3 className="text-xl font-semibold">a. Business Transactions</h3>
          <p className="mb-2">
            In the event of a business transaction, we may transfer your
            Personal Data with notice.
          </p>
        </section>

        {/* Subsection 8b: Legal Requirements */}
        <section id="section8b" className="mb-4">
          <h3 className="text-xl font-semibold">b. Legal Requirements</h3>
          <p className="mb-2">
            We may disclose your Personal Information in compliance with legal
            obligations.
          </p>
        </section>

        {/* Subsection 8c: Protecting Our Rights */}
        <section id="section8c" className="mb-4">
          <h3 className="text-xl font-semibold">c. Protecting Our Rights</h3>
          <p className="mb-2">
            We may disclose your Personal Information to protect our rights,
            safety, or security.
          </p>
        </section>
      </section>
      {/* Section 9: Security of Your Personal Information */}
      <section id="section9" className="mb-8">
        <h2 className="text-2xl font-semibold">
          9. Security of Your Personal Information
        </h2>
        <p className="mb-4">
          While we employ commercially acceptable methods to protect your
          Personal Information, no online transmission or electronic storage is
          entirely secure. We cannot guarantee absolute security.
        </p>
      </section>

      {/* Section 10: Third-Party Service Providers */}
      <section id="section10" className="mb-8">
        <h2 className="text-2xl font-semibold">
          10. Third-Party Service Providers
        </h2>

        {/* Subsection 10a: Google Places */}
        <section id="section10a" className="mb-4">
          <h3 className="text-xl font-semibold">a. Google Places</h3>
          <p className="mb-2">
            Google Places is a third-party service used for location-based
            information. It is operated by Google and subject to Google's
            Privacy Policy.
          </p>
        </section>
      </section>

      {/* Section 11: Children's Privacy */}
      <section id="section11" className="mb-8">
        <h2 className="text-2xl font-semibold">11. Children's Privacy</h2>
        <p className="mb-4">
          Our Service is not intended for individuals under the age of 13. We do
          not knowingly collect personal information from individuals under 13
          years old. If we discover such data, we delete it promptly.
        </p>
        <p className="mb-4">
          If we need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </p>
      </section>

      {/* Section 12: Links to Other Websites */}
      <section id="section12" className="mb-8">
        <h2 className="text-2xl font-semibold">12. Links to Other Websites</h2>
        <p className="mb-4">
          Our Service may contain links to third-party websites. We are not
          responsible for the content, privacy policies, or practices of these
          third-party sites.
        </p>
      </section>

      {/* Section 13: Changes to This Privacy Policy */}
      <section id="section13" className="mb-8">
        <h2 className="text-2xl font-semibold">
          13. Changes to This Privacy Policy
        </h2>
        <p className="mb-4">
          We may update our Privacy Policy from time to time.
        </p>
        <p className="mb-4">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </section>

      {/* Section 14: Contact Us */}
      <section id="section14" className="mb-8">
        <h2 className="text-2xl font-semibold">14. Contact Us</h2>
        <p className="mb-4">
          If you have questions about this Privacy Policy, please contact us:
        </p>
        <p className="mb-4">- Email: utepils-app@gmail.com</p>
      </section>
      <p className="mb-4"> Last updated: 26/08/2023</p>
    </div>
  );
}

export default Privacy;
