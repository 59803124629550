import "./App.css";
import { Link, useRoutes } from "react-router-dom";
import Routes from "./Routes";
import logo from "./assets/logo.png";
import board from "./assets/board.jpg";
// boilerplate APP component using react router
function App() {
  // const routeResult = useRoutes(routes);
  return (
    <div className="flex flex-col items-center w-screen  h-screen w-screen">
      <header>
        {/* <nav>
          <Link class="header-link" to="/">
            Home
          </Link>
        </nav> */}
        <img className="mt-20 bg-blac" src={logo} alt="Utepils" />
      </header>
      <main className="flex flex-col items-center w-full ">
        <Routes />
      </main>

      <footer className="flex">
        <Link to="/privacy">Privacy</Link>
        <p className="ml-10">Kontakt: utepilsapp@gmail.com</p>
      </footer>
    </div>
  );
}

export default App;
