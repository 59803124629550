// import "./Home.css";
import board from "../assets/board.jpg";

function Home() {
  return (
    <div className="flex flex-col items-center w-screen  h-full w-screen">
      <h1 className="text-5xl my-10 font-bold font-inter">Utepils!</h1>
      <h2 className="text-3xl font-medium font-inter">
        En komplett guide til skjenkesteder i Oslo.
      </h2>
      <p className="my-5">Nå åpen for betatesting (kun iPhone)</p>
      <p className="my-5">
        Trykk på knappen under og fyll ut en kort spørreundersøkelse for å få
        tilgang
      </p>
      <a href="https://forms.gle/uaETaHADEBHyWGbv5">
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 my-4 px-4 rounded-full">
          Test appen!
        </button>
      </a>
      <img className="w-3/4" src={board} />
    </div>
  );
}

export default Home;
